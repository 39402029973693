import { Card, Divider, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CardContainer = ({ children, extra, title }) => {
  return (
    <Card sx={{ p: 4 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" sx={{ m: 0 }}>
          {title}
        </Typography>
        {extra}
      </Stack>

      <Divider sx={{ my: 4 }} />
      {children}
    </Card>
  );
};

export default CardContainer;

CardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  extra: PropTypes.node,
  title: PropTypes.string.isRequired,
};
