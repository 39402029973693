import "./styles/index.css";
import "./styles/prosemirror.css";

import Div from "@jumbo/shared/Div";
import ErrorIcon from "@mui/icons-material/Error";
import { Divider } from "@mui/material";
import {
  EditorCommand,
  EditorCommandEmpty,
  EditorCommandItem,
  EditorCommandList,
  EditorContent,
} from "novel";
import { handleCommandNavigation, ImageResizer } from "novel/extensions";
import { handleImageDrop, handleImagePaste } from "novel/plugins";
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { defaultContent } from "./defaultContent";
import { defaultExtensions } from "./extensions";
import { uploadFn } from "./image-upload";
import SelectorGroup from "./selectors";
import ColorSelector from "./selectors/color-selector";
import LinkSelector from "./selectors/link-selector";
import MathSelector from "./selectors/math-selector";
import NodeSelector from "./selectors/node-selector";
import TextButtons from "./selectors/text-selector";
import { slashCommand, suggestionItems } from "./slash-command";

// const hljs = require('highlight.js');

const extensions = [...defaultExtensions, slashCommand];

const NovelEditor = () => {
  const [editor, setEditor] = useState(null);
  const [initialContent, setInitialContent] = useState(null);

  const isGenerating = useSelector(({ report }) => report.isGenerating);
  const isLoading = useSelector(({ report }) => report.isLoading);
  const text = useSelector(({ report }) => report.text);

  const [openSelector, setOpenSelector] = useState({
    node: false,
    color: false,
    link: false,
  });
  //Apply Codeblock Highlighting on the HTML from editor.getHTML()
  // const highlightCodeblocks = (content) => {
  //   const doc = new DOMParser().parseFromString(content, "text/html");
  //   doc.querySelectorAll("pre code").forEach((el) => {

  //     hljs.highlightElement(el);
  //   });
  //   return new XMLSerializer().serializeToString(doc);
  // };

  const debouncedUpdates = useDebouncedCallback(async (editor) => {
    // const json = editor.getJSON();
    // setCharsCount(editor.storage.characterCount.words());
    // window.localStorage.setItem(
    //   "html-content",
    //   highlightCodeblocks(editor.getHTML()),
    // );
    // window.localStorage.setItem("novel-content", JSON.stringify(json));
    // window.localStorage.setItem(
    //   "markdown",
    //   editor.storage.markdown.getMarkdown(),
    // );
    // setSaveStatus("Saved");
    console.log(editor.getHTML());
  }, 500);

  useEffect(() => {
    const content = window.localStorage.getItem("novel-content");
    if (content) setInitialContent(JSON.parse(content));
    else setInitialContent(defaultContent);
  }, []);

  const handleSelectorOpen = (selector) => {
    setOpenSelector({
      node: false,
      color: false,
      link: false,
      [selector]: true,
    });
  };

  const handleSelectorClose = (selector) => {
    if (selector) {
      setOpenSelector({
        ...openSelector,
        [selector]: false,
      });
    } else {
      setOpenSelector({
        node: false,
        color: false,
        link: false,
      });
    }
  };

  useEffect(() => {
    if (editor) {
      if (isLoading) editor.chain().setContent("等待中...").run();
      else editor.chain().setContent(text).run();
    }
  }, [editor, text, isLoading]);

  return (
    <Div sx={{ border: "1px solid #e0e0e0", borderRadius: 1 }}>
      <EditorContent
        initialContent={initialContent}
        extensions={extensions}
        className="relative min-h-[500px]"
        editable={!isGenerating}
        editorProps={{
          handleDOMEvents: {
            keydown: (_view, event) => handleCommandNavigation(event),
          },
          handlePaste: (view, event) => handleImagePaste(view, event, uploadFn),
          handleDrop: (view, event, _slice, moved) =>
            handleImageDrop(view, event, moved, uploadFn),
          attributes: {
            class:
              "prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full px-8 py-4",
          },
        }}
        onUpdate={({ editor }) => {
          debouncedUpdates(editor);
        }}
        onCreate={({ editor }) => {
          setEditor(editor);
        }}
        slotAfter={<ImageResizer />}
      >
        <EditorCommand
          style={{
            height: "auto",
            maxHeight: "330px",
            overflow: "auto",
            padding: "8px 12px",
            backgroundColor: "white",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: "4px",
          }}
        >
          <EditorCommandEmpty
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <ErrorIcon />
            No results
          </EditorCommandEmpty>
          <EditorCommandList>
            {suggestionItems.map((item) => (
              <EditorCommandItem
                value={item.title}
                onCommand={(val) => item.command(val)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                  cursor: "pointer",
                }}
                key={item.title}
              >
                <div className="flex h-10 w-10 items-center justify-center rounded-md border border-muted bg-background">
                  {item.icon}
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      margin: 0,
                    }}
                  >
                    {item.title}
                  </p>
                  <p style={{ fontSize: "14px", color: "gray", margin: 0 }}>
                    {item.description}
                  </p>
                </div>
              </EditorCommandItem>
            ))}
          </EditorCommandList>
        </EditorCommand>

        <SelectorGroup>
          <TextButtons />
          <Divider orientation="vertical" flexItem />
          <NodeSelector
            open={openSelector.node}
            onOpenChange={() => handleSelectorOpen("node")}
            onClose={() => handleSelectorClose()}
          />
          <Divider orientation="vertical" flexItem />
          <ColorSelector
            open={openSelector.color}
            onOpenChange={() => handleSelectorOpen("color")}
            onClose={() => handleSelectorClose()}
          />
          <Divider orientation="vertical" flexItem />
          <LinkSelector
            open={openSelector.link}
            onOpenChange={() => handleSelectorOpen("link")}
            onClose={() => handleSelectorClose()}
          />
          <Divider orientation="vertical" flexItem />
          <MathSelector />
        </SelectorGroup>
      </EditorContent>
    </Div>
  );
};

export default NovelEditor;
