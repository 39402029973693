import CalendarIcon from "@mui/icons-material/CalendarMonth";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useState } from "react";

const DatePickerSection = ({
  value,
  onChange,
  name,
  disableFuture = true,
  label,
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (value) => {
    onChange(name, dayjs(value).format("YYYY-MM-DD"));
    setOpen(false);
  };

  return (
    <DatePicker
      name={name}
      label={label}
      value={value}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleChange}
      disableFuture={disableFuture}
      slotProps={{
        textField: {
          InputProps: {
            onClick: () => setOpen(true),
            endAdornment: (
              <InputAdornment position="end">
                {value && (
                  <Tooltip title="清除日期">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(name, "");
                      }}
                      size="small"
                      sx={{
                        "&:hover": {
                          backgroundColor: "action.hover",
                        },
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                {!value && (
                  <IconButton onClick={() => setOpen(true)}>
                    <CalendarIcon fontSize="small" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          },
        },
      }}
    />
  );
};

export default DatePickerSection;
