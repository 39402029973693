import AdminReactionPage from "app/pages/admin/reaction";
import React from "react";

const adminReactionRoutes = [
  {
    path: "/admin/reaction",
    element: <AdminReactionPage />,
  },
];

export default adminReactionRoutes;
