import http from "app/services";
import queryString from "query-string";

import configureStore from "../store";
import { GET_REACTION_DATA, GET_REACTION_DATA_DONE } from "../types/reaction";

export const getReactionData = (gId, params = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_REACTION_DATA });

    const {
      page = 0,
      page_size = 10,
      type,
      keyword,
      start_date,
      end_date,
    } = params;

    const config = {
      page: Number(page - 1),
      page_size: Number(page_size),
      ...(type && { type }),
      ...(keyword && { keyword }),
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    };

    const store = configureStore();
    const state = store.getState();
    const prevConfig = state.reaction.getIn(["config"]);
    const currentPage = Number(page);

    const isExist = state.reaction.getIn(["results", "reactions", currentPage]);

    if (isExist) {
      return;
    }

    if (
      prevConfig.keyword !== config.keyword ||
      prevConfig.type !== config.type ||
      prevConfig.start_date !== config.start_date ||
      prevConfig.end_date !== config.end_date
    ) {
      config.page = 0;
      config.page_size = 10;
    }

    const url = queryString.stringifyUrl({
      url: `/api/v1/groups/${gId}/reactions`,
      query: config,
    });

    try {
      const res = await http.get(url);

      if (res.data.length === 0) {
        return;
      }

      dispatch({
        type: GET_REACTION_DATA_DONE,
        payload: {
          doc: res.data,
          config: {
            ...config,
            page: config.page + 1,
          },
        },
      });
    } catch (error) {
      console.error("Failed to fetch reaction data:", error);
    }
  };
};
