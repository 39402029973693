import { Typography } from "@mui/material";
import CardContainer from "app/layouts/shared/container/Card";
import HealthGroup from "app/shared/HealthGroup";
import React from "react";

const AdminDashboardPage = () => {
  return (
    <CardContainer title="管理員後台">
      <Typography variant="h3">伺服器狀態</Typography>
      <HealthGroup />
    </CardContainer>
  );
};

export default AdminDashboardPage;
