import {
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CardContainer from "app/layouts/shared/container/Card";
import { errorAlert } from "app/redux/actions/alert";
import { getReactionData } from "app/redux/actions/reaction";
import ReactionDataTable from "app/shared/DataTable/ReactionDataTable";
import DatePickerSection from "app/shared/Form/DatePickerSection";
import Loading from "app/shared/Loading";
import dayjs from "dayjs";
import { List } from "immutable";
import queryString from "query-string";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const AdminReactionPage = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const gId = useSelector(({ app }) => app.getIn(["user", "group_id"]));
  const dataSource = useSelector(({ reaction }) =>
    reaction.getIn(["results", "reactions"]),
  );

  const isLoading = useSelector(({ reaction }) =>
    reaction.getIn(["isLoading"]),
  );

  const config = useSelector(({ reaction }) => reaction.getIn(["config"]));

  const validateDate = useCallback(
    (start_date, end_date) => {
      if (start_date && end_date) {
        if (dayjs(start_date).unix() > dayjs(end_date).unix()) {
          dispatch(errorAlert("起始日期不能大於結束日期"));
          return false;
        }

        if (
          dayjs(start_date).format("YYYY-MM-DD") ===
          dayjs(end_date).format("YYYY-MM-DD")
        ) {
          dispatch(errorAlert("起始日期不能等於結束日期"));
          return false;
        }
      }
      return true;
    },
    [searchParams],
  );

  const page = Number(searchParams.get("page")) || 1;
  const page_size = Number(searchParams.get("page_size")) || 10;

  const handleSearch = () => {
    const params = {
      page,
      page_size,
    };

    if (searchParams.get("type")) params.type = searchParams.get("type");
    if (searchParams.get("keyword"))
      params.keyword = searchParams.get("keyword");
    if (searchParams.get("start_date"))
      params.start_date = searchParams.get("start_date");
    if (searchParams.get("end_date"))
      params.end_date = searchParams.get("end_date");

    dispatch(getReactionData(gId, params));
  };

  const handleChange = (key, value) => {
    const params = queryString.parse(searchParams.toString());
    const newParams = { ...params, [key]: value };

    const emptyValueMap = {
      type: "all",
      keyword: "",
      start_date: "",
      end_date: "",
    };

    if (value === emptyValueMap[key]) {
      delete newParams[key];
    }

    if (key === "start_date" || key === "end_date") {
      const isValid = validateDate(newParams.start_date, newParams.end_date);
      if (!isValid) return;
    }

    setSearchParams(newParams);
  };

  const handlePageChange = (page) => {
    setSearchParams({ ...searchParams, page });
    dispatch(getReactionData(gId, { ...searchParams, page }));
  };

  useEffect(() => {
    if (gId) {
      handleSearch();
    }
  }, [searchParams, gId]);

  // if (isLoading) return <Loading />;

  return (
    <CardContainer title="使用者互動分析">
      <Stack direction="row" spacing={2} mb={2}>
        <TextField
          name="keyword"
          label="搜尋用戶ID或對話內容"
          value={searchParams.get("keyword") || ""}
          onChange={(e) => handleChange("keyword", e.target.value)}
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     console.log(keyword);
          //   }
          // }}
          sx={{ width: "60%" }}
        />
        <DatePickerSection
          name="start_date"
          label="請輸入起始日期"
          value={
            searchParams.get("start_date")
              ? dayjs(searchParams.get("start_date"))
              : null
          }
          onChange={handleChange}
        />
        <DatePickerSection
          name="end_date"
          label="請結束輸入日期"
          value={
            searchParams.get("end_date")
              ? dayjs(searchParams.get("end_date"))
              : null
          }
          onChange={handleChange}
        />
        <Select
          name="type"
          value={searchParams.get("type") || "all"}
          onChange={(e) => handleChange("type", e.target.value)}
          sx={{
            width: "6%",
          }}
        >
          <MenuItem value="all">全部</MenuItem>
          <MenuItem value="like">讚</MenuItem>
          <MenuItem value="dislike">倒讚</MenuItem>
        </Select>
      </Stack>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 4 }}>
          總互動次數
        </Typography>
        <Typography variant="h1" fontWeight={700} sx={{ mb: 0 }}>
          1000
        </Typography>
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 4 }}>
          按讚比例
        </Typography>
        <Typography variant="h1" fontWeight={700} sx={{ mb: 0 }}>
          50%
        </Typography>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <Typography
          textAlign="center"
          variant="h3"
          fontWeight={500}
          sx={{ mb: 2 }}
        >
          詳細互動紀錄
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          mb={2}
        >
          <Button variant="contained" color="info">
            導出Excel
          </Button>
          <Button variant="contained" color="success">
            導出為常見問題
          </Button>
          <Button variant="contained">導出為訓練數據</Button>
          <Typography variant="h6">已選擇1項</Typography>
        </Stack>
        <ReactionDataTable
          page={config.page}
          page_size={config.page_size}
          dataSource={dataSource.get(config.page) || List()}
          onPageChange={handlePageChange}
        />
      </Paper>
    </CardContainer>
  );
};

export default AdminReactionPage;
