import http from "app/services";

import {
  CLEAR_REPORT_TEXT,
  GENERATE_REPORT,
  GENERATE_REPORT_FAILURE,
  GENERATE_REPORT_START,
  GENERATE_REPORT_SUCCESS,
  RECEIVE_REPORT_TEXT,
} from "../types/report";
import { statusHandler } from "./alert";

export const generateReport = (id, formData, signalKey) => {
  return async (dispatch) => {
    dispatch({ type: GENERATE_REPORT });
    const url = `/api/v1/users/${id}/reports`;

    try {
      const res = await http.stream(
        url,
        formData,
        (chunk) => {
          dispatch({ type: RECEIVE_REPORT_TEXT, payload: { text: chunk } });
        },
        signalKey,
      );

      if (res?.response && res?.response?.status >= 400) {
        dispatch(statusHandler(res?.response?.status));
        dispatch({ type: GENERATE_REPORT_FAILURE });
        return false;
      }

      dispatch({ type: GENERATE_REPORT_SUCCESS });
      await http.stopStream(signalKey);
    } catch (error) {
      dispatch({ type: GENERATE_REPORT_FAILURE });
      dispatch(statusHandler(500));
    }
  };
};

export const clearReportText = () => {
  return { type: CLEAR_REPORT_TEXT };
};
