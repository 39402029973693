import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CodeBlock = ({ language, value }) => {
  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div style={{ marginBottom: 8 }} id="code-block">
      <div
        style={{
          border: "1px solid #c0c0c0",
          borderBottom: 0,
          padding: "4px 16px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h4 style={{ fontSize: 16, margin: 0, fontWeight: 700 }}>
          {capitalizeFirstLetter(language)}
        </h4>
        <button
          id="copy-button"
          style={{ border: 0, backgroundColor: "#fff", cursor: "pointer" }}
        >
          複製程式碼
        </button>
      </div>
      <Box
        component="pre"
        data-language={language}
        className="m-0 rounded-none bg-gray-100 text-inherit	"
      >
        <code>{value}</code>
      </Box>
    </div>
  );
};

export default CodeBlock;

CodeBlock.propTypes = {
  language: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
