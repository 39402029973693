import "./themes/global.css";

import JumboApp from "@jumbo/components/JumboApp";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import JumboTheme from "@jumbo/components/JumboTheme";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppLayout from "./AppLayout";
import AppProvider from "./AppProvider";
import AppRoutes from "./AppRoutes";
import { config } from "./config/main";
import AlertProvider from "./hooks/useAlert";
import configureStore, { history } from "./redux/store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const store = configureStore();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter history={history}>
            <AppProvider>
              <JumboApp activeLayout={config.activeLayout}>
                <JumboTheme init={config.theme}>
                  <JumboRTL>
                    <JumboDialogProvider>
                      <JumboDialog />
                      <SnackbarProvider maxSnack={3}>
                        <AlertProvider>
                          <AppLayout>
                            <Suspense
                              fallback={
                                <Div
                                  sx={{
                                    display: "flex",
                                    minWidth: 0,
                                    alignItems: "center",
                                    alignContent: "center",
                                    height: "100%",
                                  }}
                                >
                                  <CircularProgress
                                    color="primary"
                                    sx={{ m: "-40px auto 0" }}
                                  />
                                </Div>
                              }
                            >
                              <AppRoutes />
                            </Suspense>
                          </AppLayout>
                        </AlertProvider>
                      </SnackbarProvider>
                    </JumboDialogProvider>
                  </JumboRTL>
                </JumboTheme>
              </JumboApp>
            </AppProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
