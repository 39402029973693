import {
  CLEAR_REPORT_TEXT,
  GENERATE_REPORT,
  GENERATE_REPORT_FAILURE,
  GENERATE_REPORT_START,
  GENERATE_REPORT_SUCCESS,
  RECEIVE_REPORT_TEXT,
} from "../types/report";

const INIT_STATE = {
  isLoading: false,
  isGenerating: false,
  text: "",
};

const reportReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GENERATE_REPORT: {
      return {
        ...INIT_STATE,
        isLoading: true,
        isGenerating: true,
        text: "",
      };
    }
    case RECEIVE_REPORT_TEXT: {
      const text = _.text;
      return {
        ..._,
        text: text + payload.text,
        isLoading: false,
      };
    }
    case GENERATE_REPORT_SUCCESS: {
      return {
        ..._,
        isGenerating: false,
      };
    }
    case GENERATE_REPORT_FAILURE: {
      return {
        ..._,
        isGenerating: false,
      };
    }
    case CLEAR_REPORT_TEXT: {
      return {
        ..._,
        text: "",
      };
    }
    default:
      return _;
  }
};

export default reportReducer;
