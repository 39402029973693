import HelpIcon from "@mui/icons-material/Help";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  Checkbox,
  Chip,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";

const ReactionDataTable = ({ page, page_size, dataSource, onPageChange }) => {
  const formatReaction = (reaction) => {
    if (reaction === "like")
      return (
        <Chip
          label="喜歡"
          color="success"
          icon={<ThumbUpIcon sx={{ mr: 1, fontSize: 16 }} />}
        />
      );
    if (reaction === "dislike")
      return (
        <Chip
          label="不喜歡"
          color="error"
          icon={<ThumbDownIcon sx={{ mr: 1, fontSize: 16 }} />}
        />
      );
    return (
      <Chip
        label="未知"
        color="warning"
        icon={<HelpIcon sx={{ mr: 1, fontSize: 16 }} />}
      />
    );
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox />
              </TableCell>
              <TableCell>用戶ID</TableCell>
              <TableCell>對話ID</TableCell>
              <TableCell>輸入內容</TableCell>
              <TableCell>回應內容</TableCell>
              <TableCell>反應</TableCell>
              <TableCell>時間</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map((reaction) => (
              <TableRow key={reaction.get("id")}>
                <TableCell>
                  <Checkbox />
                </TableCell>
                <TableCell>{reaction.get("user_id")}</TableCell>
                <TableCell>{reaction.get("log_id")}</TableCell>
                <TableCell>{reaction.get("question")}</TableCell>
                <TableCell>{reaction.get("answer")}</TableCell>
                <TableCell>
                  {formatReaction(reaction.get("reaction"))}
                </TableCell>
                <TableCell>
                  {dayjs(reaction.get("created_at")).format(
                    "YYYY-MM-DD HH:mm:ss",
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="flex-end">
        <Pagination
          count={dataSource.size + 1}
          page={page}
          onChange={(e, page) => {
            onPageChange(page);
          }}
        />
      </Stack>
    </>
  );
};

export default ReactionDataTable;

ReactionDataTable.propTypes = {
  page: PropTypes.number.isRequired,
  page_size: PropTypes.number.isRequired,
  dataSource: PropTypes.instanceOf(Immutable.OrderedMap).isRequired,
};
