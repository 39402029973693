import Immutable from "immutable";

import { GET_REACTION_DATA, GET_REACTION_DATA_DONE } from "../types/reaction";

const INIT_STATE = Immutable.OrderedMap({
  isLoading: false,
  results: Immutable.OrderedMap({
    reactions: Immutable.OrderedMap(),
  }),
  config: {
    page: 0,
    page_size: 10,
  },
});

const reactionReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REACTION_DATA: {
      return _.set("isLoading", true);
    }
    case GET_REACTION_DATA_DONE: {
      const doc = Immutable.fromJS(payload.doc);
      return _.setIn(["results", "reactions", payload.config.page], doc)
        .setIn(["config"], payload.config)
        .set("isLoading", false);
    }
    default:
      return _;
  }
};

export default reactionReducer;
